import {Flow, FlowStep} from '@/service/flow/Flow'
import {SiteManagerDeliveryRequestRoute} from '@/router/sitemanager'
import {RouteLocationRaw} from 'vue-router'
import {SiteManagerOrderBean} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {SiteBean} from '@/service/model/SiteBean'
import _ from 'lodash'
import {QuarryBean} from '@/service/model/QuarryBean'
import {MaterialBean} from '@/service/model/MaterialBean'

export interface Location {
  latitude: number
  longitude: number
}
export interface DeliveryRequestFlowState {
  preselectedOrder?: SiteManagerOrderBean
  site?: SiteBean
  order?: SiteManagerOrderBean
  tripCount?: number
  material?: MaterialBean
  backloadMaterial?: MaterialBean
  trucksType?: string
  quantity?: number
  day?: string
  timePreference?: string
  responseTime?: string
  address1?: string
  address2?: string
  city?: string
  postalCode?: string
  comment?: string
  location?: Location
  deliveryType?: string
}

export function getSites(orders: Array<SiteManagerOrderBean>): Array<SiteBean> {
  const sites = {}
  for (const order of orders) {
    sites[order.site.id || ''] = order.site
  }
  return _.values(sites)
}

export function getQuarries(orders: Array<SiteManagerOrderBean>, state: DeliveryRequestFlowState): Array<QuarryBean> {
  const quarries = {}
  for (const order of orders) {
    if (order.site.id !== state?.site?.id) {
      continue
    }
    quarries[order.quarry.id || ''] = order.quarry
  }
  return _.values(quarries)
}

export function getOrders(
  orders: Array<SiteManagerOrderBean>,
  state: DeliveryRequestFlowState,
  quarry: QuarryBean
): Array<SiteManagerOrderBean> {
  const filteredOrders: Array<SiteManagerOrderBean> = []
  for (const order of orders) {
    if (order.site.id !== state?.site?.id) {
      continue
    }
    if (order.quarry.id !== quarry.id) {
      continue
    }
    filteredOrders.push(order)
  }
  return filteredOrders
}

export function getOrdersBySite(
  orders: Array<SiteManagerOrderBean>,
  site: SiteBean
): Array<SiteManagerOrderBean> {
  const filteredOrders: Array<SiteManagerOrderBean> = []
  for (const order of orders) {
    if (order.site.id !== site?.id) {
      continue
    }
    filteredOrders.push(order)
  }
  return filteredOrders
}

export const DeliveryRequestFlowSelectSiteStep: FlowStep<Array<SiteManagerOrderBean>, DeliveryRequestFlowState> = {
  routeName: SiteManagerDeliveryRequestRoute.SELECT_SITE,
  canEnter: (item, state) => state.preselectedOrder === undefined,
  isCompleted: state => state.site !== undefined
}

export const DeliveryRequestFlowSelectOrderStep: FlowStep<Array<SiteManagerOrderBean>, DeliveryRequestFlowState> = {
  routeName: SiteManagerDeliveryRequestRoute.SELECT_ORDER,
  canEnter: (item, state) => state.preselectedOrder === undefined,
  isCompleted: state => state.order !== undefined
}

export const DeliveryRequestFlowTripCountStep: FlowStep<Array<SiteManagerOrderBean>, DeliveryRequestFlowState> = {
  routeName: SiteManagerDeliveryRequestRoute.MATERIAL_CHOICE,
  isCompleted: state => state.tripCount !== undefined
}

export const DeliveryRequestFlowTrucksChoiceStep: FlowStep<Array<SiteManagerOrderBean>, DeliveryRequestFlowState> = {
  routeName: SiteManagerDeliveryRequestRoute.TRUCKS_CHOICE,
  //isCompleted: state => state.truckChoice !== undefined
}

export const DeliveryRequestFlowDayStep: FlowStep<Array<SiteManagerOrderBean>, DeliveryRequestFlowState> = {
  routeName: SiteManagerDeliveryRequestRoute.DAY,
  isCompleted: state => state.day !== undefined
}

export const DeliveryRequestFlowConfirmStep: FlowStep<Array<SiteManagerOrderBean>, DeliveryRequestFlowState> = {
  routeName: SiteManagerDeliveryRequestRoute.CONFIRM
}

export class DeliveryRequestFlow extends Flow<null, Array<SiteManagerOrderBean>, DeliveryRequestFlowState> {
  
  constructor(
    private readonly initialRoute?: RouteLocationRaw
  ) {
    super(null, [], {})
  }
  
  setPreselectedOrder(order?: SiteManagerOrderBean) {
    this.assignState({
      preselectedOrder: order,
      site: order?.site,
      order: order,
      tripCount: undefined,
      material: undefined,
      backloadMaterial: undefined,
      trucksType: undefined,
      quantity: undefined,
      day: undefined,
      timePreference: undefined,
      responseTime: undefined,
      address1: undefined,
      address2: undefined,
      city: undefined,
      postalCode: undefined,
      location: undefined,
      comment: undefined
    })
  }
  
  setSite(site?: SiteBean) {
    this.assignState({
      preselectedOrder: undefined,
      site: site,
      order: undefined,
      tripCount: undefined,
      material: undefined,
      backloadMaterial: undefined,
      trucksType: undefined,
      quantity: undefined,
      day: undefined,
      timePreference: undefined,
      responseTime: undefined,
      address1: undefined,
      address2: undefined,
      city: undefined,
      postalCode: undefined,
      location: undefined,
      comment: undefined
    })
  }
  
  setOrder(order?: SiteManagerOrderBean) {
    this.assignState({
      order: order,
      tripCount: undefined,
      material: undefined,
      backloadMaterial: undefined,
      trucksType: undefined,
      quantity: undefined,
      day: undefined,
      timePreference: undefined,
      responseTime: undefined,
      address1: undefined,
      address2: undefined,
      city: undefined,
      postalCode: undefined,
      location: undefined,
      comment: undefined
    })
  }
  
  setTripCount(tripCount?: number) {
    this.assignState({
      tripCount: tripCount
    })
  }
  

  setMaterial(material?: MaterialBean) {
    this.assignState({
      material: material
    })
  }
  
  setBackloadMaterial(backloadMaterial?: MaterialBean) {
    this.assignState({
      backloadMaterial: backloadMaterial
    })
  }
  
  setTrucksType(trucksType?: string) {
    this.assignState({
      trucksType: trucksType
    })
  }

  setQuantity(quantity?: number) {
    this.assignState({
      quantity: quantity
    })
  }

  setDay(day?: string) {
    this.assignState({
      day: day
    })
  }
  
  setTimePreference(timePreference?: string) {
    this.assignState({
      timePreference: timePreference
    })
  }

  setResponseTime(responseTime?: string) {
    this.assignState({
      responseTime: responseTime
    })
  }
  
  setAaddress1(address1?: string) {
    this.assignState({
      address1: address1
    })
  }

  setAaddress2(address2?: string) {
    this.assignState({
      address2: address2
    })
  }

  setCity(city?: string) {
    this.assignState({
      city: city
    })
  }

  setPostalCode(postalCode?: string) {
    this.assignState({
      postalCode: postalCode
    })
  }

  setLocation(location?: Location) {
    this.assignState({
      location: location
    })
  }

  setComment(comment?: string) {
    this.assignState({
      comment: comment
    })
  }
  
  setDeliveryType(deliveryType?: string) {
    this.assignState({
      deliveryType: deliveryType
    })
  }
  getOutputRoute(): RouteLocationRaw {
    if (this.initialRoute !== undefined) {
      return this.initialRoute
    } else {
      return {
        name: 'SiteManagerOrders'
      }
    }
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCancelRoute(): RouteLocationRaw {
    if (this.initialRoute !== undefined) {
      return this.initialRoute
    } else {
      return {
        name: 'SiteManagerOrders'
      }
    }
  }
  
  getSteps(): Array<FlowStep<Array<SiteManagerOrderBean>, DeliveryRequestFlowState>> {
    return [
      DeliveryRequestFlowSelectSiteStep,
      DeliveryRequestFlowSelectOrderStep,
      DeliveryRequestFlowTripCountStep,
      DeliveryRequestFlowTrucksChoiceStep,
      DeliveryRequestFlowDayStep,
      DeliveryRequestFlowConfirmStep
    ]
  }
}
