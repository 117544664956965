<template>
  <main-menu-divider v-bind:label="t('quotation.title')"/>
  <main-menu-item v-bind:label="t('quotation.list')"
                  v-bind:to="{name: 'SiteManagerQuotationRequests'}"/>
  <main-menu-item v-bind:icon="add"
                  v-bind:label="t('quotation.add')"
                  v-on:item-click="onQuotationRequestClicked"/>

  <main-menu-divider v-bind:label="t('order.title')"/>
  <main-menu-item v-bind:label="t('order.list')"
                  v-bind:to="{name: 'SiteManagerOrders'}"/>
  <main-menu-item v-bind:icon="add"
                  v-bind:label="t('order.addExpressOrder')"
                  v-on:item-click="onCreateExpressOrderClicked"/>


    <main-menu-divider v-bind:label="t('order.deliveryRequest')"/>
    <div @click="toggleAccordion(1)" class="accordion-header sc-ion-label-md-h sc-ion-label-md-s">
      <span>{{ t('deliveryRequest.title') }}</span>
      <ion-icon :icon="isOpen1 ? chevronUp : chevronDown" class="accordion-icon"></ion-icon>
    </div>
    <div :class="{ 'is-hidden': !isOpen1 }">
      <main-menu-item v-bind:label="t('deliveryRequest.inProgress')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'pending', type : RequestType.DELIVERY} }"/>
      <main-menu-item v-bind:label="t('deliveryRequest.accepted')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'accepted', type : RequestType.DELIVERY} }"/>
      <main-menu-item v-bind:label="t('deliveryRequest.refused')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'rejected', type : RequestType.DELIVERY} }"/>
      <main-menu-item v-bind:label="t('deliveryRequest.expired')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'expired', type : RequestType.DELIVERY} }"/>
      <main-menu-item v-bind:label="t('deliveryRequest.delivered')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'delivered', type : RequestType.DELIVERY} }"/>
      <main-menu-item v-bind:label="t('deliveryRequest.disputes')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'disputed', type : RequestType.DELIVERY} }"/>
      <main-menu-item v-bind:icon="add"
                      v-bind:label="t('deliveryRequest.title')"
                      v-on:item-click="onDeliveryRequestClicked(RequestType.DELIVERY)"/>
    </div>
    <div @click="toggleAccordion(2)" class="accordion-header">
      <span>{{ t('pickUpRequest.title') }}</span>
      <ion-icon :icon="isOpen2 ? chevronUp : chevronDown" class="accordion-icon"></ion-icon>
    </div>
    <div :class="{ 'is-hidden': !isOpen2 }">
      <main-menu-item  v-bind:label="t('pickUpRequest.inProgress')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'pending', type : RequestType.PICKUP} }"/>
      <main-menu-item v-bind:label="t('pickUpRequest.accepted')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'accepted', type : RequestType.PICKUP} }"/>
      <main-menu-item  v-bind:label="t('pickUpRequest.refused')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'rejected', type : RequestType.PICKUP} }"/>
      <main-menu-item  v-bind:label="t('pickUpRequest.expired')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'expired', type : RequestType.PICKUP} }"/>
      <main-menu-item  v-bind:label="t('pickUpRequest.taked')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'delivered', type : RequestType.PICKUP} }"/>
      <main-menu-item  v-bind:label="t('pickUpRequest.disputes')"
                      v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'disputed', type : RequestType.PICKUP} }"/>
      <main-menu-item  v-bind:icon="add"
                      v-bind:label="t('pickUpRequest.title')"
                      v-on:item-click="onDeliveryRequestClicked(RequestType.PICKUP)"/>
    </div>

  <main-menu-divider v-bind:label="t('deliveryNote.title')"/>
  <main-menu-item v-bind:label="t('deliveryNote.upcoming')"
                  v-bind:to="{name: SiteManagerDeliveryNotesRoute.UPCOMING}"/>
  <main-menu-item v-bind:label="t('deliveryNote.delivered')"
                  v-bind:to="{name: SiteManagerDeliveryNotesRoute.DELIVERED}"/>
  <main-menu-item v-bind:icon="add"
                  v-bind:label="t('deliveryNote.addPickupDeliveryNote')"
                  v-on:item-click="onCreatePickupDeliveryNoteButtonClicked"/>

  <main-menu-divider v-bind:label="t('config.title')"/>
  <main-menu-item v-bind:label="t('config.drivers')"
                  v-bind:to="{name: 'SiteManagerDrivers'}"/>
  <main-menu-item v-bind:label="t('config.truckRegistrations')"
                  v-bind:to="{name: 'SiteManagerTrucks'}"/>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {useI18n} from 'vue-i18n'
import MainMenuItem from '@/components/common/menu/MainMenuItem.vue'
import MainMenuDivider from '@/components/common/menu/MainMenuDivider.vue'
import {SiteManagerDeliveryNotesRoute} from '@/router/sitemanager'
import {mapActions} from 'vuex'
import {add, chevronDown, chevronUp} from 'ionicons/icons'
import { RequestType } from '@/service/DeliveryTypes'

export default defineComponent({
  name: 'site-manager-menu',
  data() {
    return {
      isOpen1: false,
      isOpen2: false,
    }
  },
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {
      t,
      add,
      SiteManagerDeliveryNotesRoute, 
      chevronDown, chevronUp,
      RequestType
    }
  },
  methods: {
    ...mapActions('siteManager/expressOrder', ['createExpressOrderFlow']),
    ...mapActions('siteManager/deliveryRequest', {
      createDeliveryRequestFlow: 'createFlow'
    }),
    ...mapActions('siteManager/quotationRequest/create', ['createQuotationRequestFlow']),
    ...mapActions('siteManager/pickup', ['createPickupFlow']),
    toggleAccordion(index) {
      if (index === 1) {
        this.isOpen1 = !this.isOpen1
      } else if (index === 2) {
        this.isOpen2 = !this.isOpen2
      }
    },
    async onCreateExpressOrderClicked() {
      const flow = await this.createExpressOrderFlow({
        initialRoute: this.$route
      })
      const nextRoute = flow.getNextRoute()
      await this.$router.replace(nextRoute)
    },
    async onDeliveryRequestClicked(type) {
      const flow = await this.createDeliveryRequestFlow({
        initialRoute: this.$route
      })
      const nextRoute = flow.getNextRoute()
      const routeWithParams = {
        ...nextRoute,
        params: { ...nextRoute.params, type }
      }
      await this.$router.replace(routeWithParams)
    },
    async onQuotationRequestClicked() {
      const flow = await this.createQuotationRequestFlow({
        initialRoute: this.$route
      })
      const nextRoute = flow.getNextRoute()
      await this.$router.replace(nextRoute)
    },
    async onCreatePickupDeliveryNoteButtonClicked() {
      const flow = await this.createPickupFlow({
        initialRoute: this.$route
      })
      const nextRoute = flow.getNextRoute()
      await this.$router.replace(nextRoute)
    }
  },
  components: {
    MainMenuDivider, MainMenuItem
  }
})
</script>

<style scoped>
.accordion-header {
  padding: 15px 15px 15px 45px;
  font-size: 14px;
  background-color: var(--ion-color-light, #f4f5f8);
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between; 
  align-items: center;
}

.accordion-icon {
  font-size: 20px;
}

  .is-hidden {
    display: none;
  }
</style>

<i18n locale="en">
{
  "quotation": {
    "title": "Quotations",
    "list": "Quotation requests",
    "add": "New request"
  },
  "order": {
    "title": "Orders",
    "list": "Orders",
    "addExpressOrder": "New express order",
    "deliveryRequest": "New delivery request"
  },
  "deliveryRequest": {
    "title": "Delivery calls",
    "accepted": "Accepted",
    "inProgress": "In Progress",
    "refused": "Refused",
    "expired": "Expired",
    "delivered": "Delivered",
    "disputes": "Disputes"
  },
  "deliveryNote": {
    "title": "Delivery notes",
    "upcoming": "Upcoming",
    "delivered": "Delivered",
    "addPickupDeliveryNote": "New pickup delivery"
  },
  "config": {
    "title": "Configuration",
    "drivers": "Drivers",
    "truckRegistrations": "Trucks"
  }
}
</i18n>

<i18n locale="fr">
{
  "quotation": {
    "title": "Devis",
    "list": "Demandes de devis",
    "add": "Nouvelle demande"
  },
  "order": {
    "title": "Commandes",
    "list": "Commandes",
    "addExpressOrder": "Nouvelle commande express",
    "deliveryRequest": "Planification de livraison"
  },
  "deliveryRequest": {
    "title": "Appels de livraison",
    "accepted": "Acceptés",
    "inProgress": "En cours",
    "refused": "Refusés",
    "expired": "Expirés",
    "delivered": "Livrés",
    "disputes": "Litiges"
  },
  "pickUpRequest": {
    "title": "Appels à enlèvement",
    "accepted": "Acceptés",
    "inProgress": "En cours",
    "refused": "Refusés",
    "expired": "Expirés",
    "taked": "Enlevés",
    "disputes": "Litiges"
  },
  "deliveryNote": {
    "title": "Bons de livraison",
    "upcoming": "En cours",
    "delivered": "Terminés",
    "addPickupDeliveryNote": "Nouveau bon départ"
  },
  "config": {
    "title": "Configuration",
    "drivers": "Chauffeurs",
    "truckRegistrations": "Camions"
  }
}
</i18n>
