import {LocationBean, locationSchema} from '@/service/model/LocationBean'
import {JSONSchemaType} from 'ajv'
import {AddressBean, addressSchema} from '@/service/model/AddressBean'
import {isSameLocation} from '@/utils/GeoFormatters'

export interface QuarryBean extends AddressBean {
  id: string
  company?: string | null
  name?: string | null
  registration?: string | null
  email?: string | null
  phone_number?: string | null
  location?: LocationBean | null
  has_automatic_delivery_note_integration?: boolean | null
}

export function isSameQuarry(quarry?: QuarryBean | null, other?: QuarryBean | null) {
  if (quarry?.name !== other?.name) {
    return false
  }
  if (quarry?.registration !== other?.registration) {
    return false
  }
  if (quarry?.email !== other?.email) {
    return false
  }
  if (quarry?.phone_number !== other?.phone_number) {
    return false
  }
  // noinspection RedundantIfStatementJS
  if (!isSameLocation(quarry?.location, other?.location)) {
    return false
  }
  return true
}

export const quarrySchema: JSONSchemaType<QuarryBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    company: {
      type: ['string'],
      nullable: true
    },
    name: {
      type: ['string'],
      nullable: true
    },
    registration: {
      type: ['string'],
      nullable: true
    },
    email: {
      type: ['string'],
      nullable: true
    },
    phone_number: {
      type: ['string'],
      nullable: true
    },
    has_automatic_delivery_note_integration: {
      type: ['boolean'],
      nullable: true
    },
    ...addressSchema.properties,
    location: {
      ...locationSchema,
      nullable: true
    }
  },
  required: ['id']
}
