export enum RequestType {
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
}

export type RequestStatus =
  | 'pending'
  | 'accepted'
  | 'rejected'
  | 'expired'
  | 'delivered'
  | 'disputed';
