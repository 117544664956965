import {RouteLocationRaw, Router} from 'vue-router'
import {deepFreeze} from '@/utils/ObjectUtils'
import _ from 'lodash'
import deepmerge from 'deepmerge'

/**
 * Interface defining a step in a flow.
 */
export interface FlowStep<Item, State> {
  routeName: string
  /**
   * Return true if the step can be entered by the user.
   * Considered always true if missing.
   */
  canEnter?: (item: Item, state: State) => boolean
  /**
   * Return true if the step has been completed by the user according to the state.
   * Considered always true if missing.
   */
  isCompleted?: (state: State) => boolean
}

export abstract class Flow<Id, Item, State> {
  
  protected constructor(
    readonly id: Id,
    private item: Item,
    private state: State
  ) {
  }
  
  getItem(): Item {
    const item = _.cloneDeep(this.item)
    return deepFreeze(item)
  }
  
  getState(): State {
    const state = _.cloneDeep(this.state)
    return deepFreeze(state)
  }
  
  /**
   * Return true if the user is actually in one of the steps.
   */
  isUserInFlow(router?: Router): boolean {
    const routeName = router?.currentRoute?.value?.name?.toString()
    if (!routeName) {
      return false
    }
    return this.getSteps().map(it => it.routeName).indexOf(routeName) !== -1
  }
  
  /**
   * List of keys contained in the state that must be transferred without using deepmerge. ex. Blob.
   */
  getKeysToCopyWithoutDeepMerge(): Array<string> {
    return []
  }
  
  /**
   * List of steps composing the flows.
   *
   * The order in the returned array will be respected.
   */
  abstract getSteps(): Array<FlowStep<Item, State>>
  
  /**
   * Return the route the user will be redirected to if the user cancel the flow.
   */
  abstract getCancelRoute(item: Item): RouteLocationRaw
  
  /**
   * Return the route the user will be redirected to when the user has completed the flow.
   */
  abstract getOutputRoute(item: Item): RouteLocationRaw
  
  updateItem(item: Item) {
    this.item = item
  }
  
  assignState(source: any): Id & State {
    this.state = deepmerge(this.state, source, {
      clone: false,
      customMerge: this.customStateKeyMerge.bind(this)
    })
    return {...this.id, ...this.getState()}
  }
  
  private customStateKeyMerge(key: string): ((x: any, y: any) => any) | undefined {
    const keys = this.getKeysToCopyWithoutDeepMerge()
    if (keys.length === 0) {
      return undefined
    }
    if (keys.includes(key)) {
      return (x, y) => y
    }
    return undefined
  }
  
  getNextRoute(currentRoute?: string, skipCompleted?: boolean, type?: string): RouteLocationRaw {
    const steps = this.getSteps()
    const currentStep = this.getCurrentStep(currentRoute)
    
    let index = currentStep?.index
    if (index === undefined) {
      index = -1
    }
    while ((++index) < steps.length) {
      const step = steps[index]
      if (this.canEnterNext(step, skipCompleted)) {
        return {
          name: step.routeName,
          params: { type } 
        }
      }
    }
    return this.getOutputRoute(this.item)
  }
  
  private canEnterNext(step: FlowStep<Item, State>, skipCompleted?: boolean): boolean {
    if (!this.canEnter(step)) {
      return false
    }
    if (skipCompleted === undefined || !skipCompleted) {
      return true
    }
    return step.isCompleted === undefined || !step.isCompleted(this.state)
  }
  
  private canEnter(step: FlowStep<Item, State>): boolean {
    return step.canEnter === undefined || step.canEnter(this.item, this.state)
  }
  
  getPreviousRoute(currentRoute: string, type?: string): RouteLocationRaw {
    const steps = this.getSteps()
    const currentStep = this.getCurrentStep(currentRoute)
    
    let index = currentStep?.index
    if (index === undefined) {
      index = -1
    }
    while ((--index) >= 0) {
      const step = steps[index]
      if (this.canEnter(step)) {
        return {
          name: step.routeName,
          params: { type }
        }
      }
    }
    return this.getCancelRoute(this.item)
  }
  
  private getCurrentStep(currentRoute?: string): { index: number; step: FlowStep<Item, State> } | undefined {
    if (!currentRoute) {
      return undefined
    }
    
    const index = this.getSteps().findIndex(it => it.routeName === currentRoute)
    if (index === -1) {
      return undefined
    }
    return {index, step: this.getSteps()[index]}
  }
}
