import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_menu_divider = _resolveComponent("main-menu-divider")!
  const _component_main_menu_item = _resolveComponent("main-menu-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('quotation.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('quotation.list'),
      to: {name: 'SiteManagerQuotationRequests'}
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      icon: _ctx.add,
      label: _ctx.t('quotation.add'),
      onItemClick: _ctx.onQuotationRequestClicked
    }, null, 8, ["icon", "label", "onItemClick"]),
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('order.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('order.list'),
      to: {name: 'SiteManagerOrders'}
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      icon: _ctx.add,
      label: _ctx.t('order.addExpressOrder'),
      onItemClick: _ctx.onCreateExpressOrderClicked
    }, null, 8, ["icon", "label", "onItemClick"]),
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('order.deliveryRequest')
    }, null, 8, ["label"]),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleAccordion(1))),
      class: "accordion-header sc-ion-label-md-h sc-ion-label-md-s"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.t('deliveryRequest.title')), 1),
      _createVNode(_component_ion_icon, {
        icon: _ctx.isOpen1 ? _ctx.chevronUp : _ctx.chevronDown,
        class: "accordion-icon"
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({ 'is-hidden': !_ctx.isOpen1 })
    }, [
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('deliveryRequest.inProgress'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'pending', type : _ctx.RequestType.DELIVERY} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('deliveryRequest.accepted'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'accepted', type : _ctx.RequestType.DELIVERY} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('deliveryRequest.refused'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'rejected', type : _ctx.RequestType.DELIVERY} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('deliveryRequest.expired'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'expired', type : _ctx.RequestType.DELIVERY} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('deliveryRequest.delivered'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'delivered', type : _ctx.RequestType.DELIVERY} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('deliveryRequest.disputes'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'disputed', type : _ctx.RequestType.DELIVERY} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        icon: _ctx.add,
        label: _ctx.t('deliveryRequest.title'),
        onItemClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDeliveryRequestClicked(_ctx.RequestType.DELIVERY)))
      }, null, 8, ["icon", "label"])
    ], 2),
    _createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleAccordion(2))),
      class: "accordion-header"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.t('pickUpRequest.title')), 1),
      _createVNode(_component_ion_icon, {
        icon: _ctx.isOpen2 ? _ctx.chevronUp : _ctx.chevronDown,
        class: "accordion-icon"
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({ 'is-hidden': !_ctx.isOpen2 })
    }, [
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('pickUpRequest.inProgress'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'pending', type : _ctx.RequestType.PICKUP} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('pickUpRequest.accepted'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'accepted', type : _ctx.RequestType.PICKUP} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('pickUpRequest.refused'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'rejected', type : _ctx.RequestType.PICKUP} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('pickUpRequest.expired'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'expired', type : _ctx.RequestType.PICKUP} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('pickUpRequest.taked'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'delivered', type : _ctx.RequestType.PICKUP} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        label: _ctx.t('pickUpRequest.disputes'),
        to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'disputed', type : _ctx.RequestType.PICKUP} }
      }, null, 8, ["label", "to"]),
      _createVNode(_component_main_menu_item, {
        icon: _ctx.add,
        label: _ctx.t('pickUpRequest.title'),
        onItemClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDeliveryRequestClicked(_ctx.RequestType.PICKUP)))
      }, null, 8, ["icon", "label"])
    ], 2),
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('deliveryNote.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryNote.upcoming'),
      to: {name: _ctx.SiteManagerDeliveryNotesRoute.UPCOMING}
    }, null, 8, ["label", "to"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryNote.delivered'),
      to: {name: _ctx.SiteManagerDeliveryNotesRoute.DELIVERED}
    }, null, 8, ["label", "to"]),
    _createVNode(_component_main_menu_item, {
      icon: _ctx.add,
      label: _ctx.t('deliveryNote.addPickupDeliveryNote'),
      onItemClick: _ctx.onCreatePickupDeliveryNoteButtonClicked
    }, null, 8, ["icon", "label", "onItemClick"]),
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('config.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('config.drivers'),
      to: {name: 'SiteManagerDrivers'}
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('config.truckRegistrations'),
      to: {name: 'SiteManagerTrucks'}
    }, null, 8, ["label"])
  ], 64))
}