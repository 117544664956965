export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "fr",
    "resource": {
      "quotation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devis"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes de devis"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle demande"])}
      },
      "order": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
        "addExpressOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle commande express"])},
        "deliveryRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planification de livraison"])}
      },
      "deliveryRequest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels de livraison"])},
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptés"])},
        "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
        "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusés"])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirés"])},
        "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livrés"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litiges"])}
      },
      "pickUpRequest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels à enlèvement"])},
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptés"])},
        "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
        "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusés"])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirés"])},
        "taked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlevés"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litiges"])}
      },
      "deliveryNote": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bons de livraison"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
        "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminés"])},
        "addPickupDeliveryNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau bon départ"])}
      },
      "config": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "drivers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chauffeurs"])},
        "truckRegistrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camions"])}
      }
    }
  })
}
