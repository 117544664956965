import {I18n} from 'vue-i18n'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {
  siteManagerOrderArraySchema,
  SiteManagerOrderBean,
  siteManagerOrderSchema
} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {deliveryNoteArraySchema, DeliveryNoteBean} from '@/service/model/DeliveryNoteBean'
import {Picture} from '@/service/model/PictureBean'
import {AbstractPictureApi} from '@/service/api/AbstractPictureApi'
import {SynchronizationService} from '@/service/SynchronizationService'
import _ from 'lodash'

export const TimePreference = {
  AM: 'AM',
  PM: 'PM',
  NONE: 'NONE'
}

export interface Location {
  latitude: number
  longitude: number
}
/*
export interface SiteManagerDeliveryRequestBean {
  material_id: string
  backload_material_id?: string
  day: string
  time_preference: string
  trip_count: number
  comment?: string
  trucksType?: string
  quantity?: number
}
*/

export interface SiteManagerDeliveryRequestBean {
  id?: string | null
  reference?: string | null
  orderId?: string
  siteManagerId?: string
  deliveryDate?: string
  timeSlotDeliveryStartTime?: string
  timeSlotDeliveryEndTime?: string
  responseDelay?: number | null
  quantity?: number
  truckType?: string
  comment?: string
  status?: string
  backloadDeliveryOnly?: boolean
  materialDeliveryOnly?: boolean
  materialDeliveryAndBackloadReturn?: boolean
  address1?: string
  address2?: string
  postalCode?: string
  city?: string
  location?: Location
  inDispute?: boolean | null
  decisionComment?: string | null
  disputeComment?: string | null
  disputeReasons?: Array<string> | null
  hasAutomaticDeliveryNoteIntegration?: boolean | null
  deliveryNotes?: DeliveryNoteBean  | null
  materialNetWeight?: MaterialNetWeight | null
  deliveryRequestType?: string | null
}

export interface MaterialNetWeight {
  materialPercentage?: number | null
  materialNetWeight?: number | null
  wastePercentage?: number | null
  wasteNetWeight?: number | null
}

export interface DisputeDto {
  disputeReasons?: string | null
  disputeComment?: string | null
}
export class SiteManagerOrderApi extends AbstractPictureApi {
  
  constructor(
    i18n: I18n,
    authenticationService: AuthenticationService,
    synchronizationService: SynchronizationService
  ) {
    super(i18n, authenticationService, synchronizationService)
  }
  
  async fetchOrders(): Promise<Array<SiteManagerOrderBean>> {
    return this.get<Array<SiteManagerOrderBean>>({
      path: '/api/mp/v1/site-manager/orders',
      authentication: 'required',
      schema: siteManagerOrderArraySchema
    })
  }
  
  async fetchOrder(id: string): Promise<SiteManagerOrderBean> {
    return this.get<SiteManagerOrderBean>({
      path: `/api/mp/v1/site-manager/orders/${id}`,
      authentication: 'required',
      schema: siteManagerOrderSchema
    })
  }
  
  async fetchPickupOrders(): Promise<Array<SiteManagerOrderBean>> {
    return this.get<Array<SiteManagerOrderBean>>({
      path: '/api/mp/v1/site-manager/orders/pick-up',
      authentication: 'required',
      schema: siteManagerOrderArraySchema
    })
  }
  
  async fetchDeliveredOrders(): Promise<Array<SiteManagerOrderBean>> {
    return this.get<Array<SiteManagerOrderBean>>({
      path: '/api/mp/v1/site-manager/orders/delivered',
      authentication: 'required',
      schema: siteManagerOrderArraySchema
    })
  }
  
  async fetchDeliveryNotes(orderId: string, limit: number, pageNumber: number): Promise<Array<DeliveryNoteBean>> {
    return this.get<Array<DeliveryNoteBean>>({
      path: `/api/mp/v1/site-manager/orders/${orderId}/delivery-notes?limit=${limit}&pageNumber=${pageNumber}`,
      authentication: 'required',
      schema: deliveryNoteArraySchema
    })
  }

  async getDeliveryRequest(id: string): Promise<SiteManagerDeliveryRequestBean> {
    return this.get({
      path: `/api/mp/v1/delivery-request/${id}`,
      authentication: 'required'
    })
  }

  async getDeliveryRequestWithDeliveryNote(params : {deliveryRequestId: string; materialId: string}): Promise<Array<DeliveryNoteBean>> {
    return this.post({
      path: 'api/mp/v1/delivery-request/link-to-delivery-note',
      authentication: 'required',
      body: params
    })
  }

  async confirmDelivery(deliveryRequestId: string): Promise<void> {
    return this.putVoid({
      path: `/api/mp/v1/delivery-request/${deliveryRequestId}/confirm-delivery`,
      authentication: 'required'
    })
  }

  async requestDelivery(deliveryRequestDto: SiteManagerDeliveryRequestBean): Promise<void> {
    return this.postVoid({
      path: '/api/mp/v1/delivery-request',
      authentication: 'required',
      body: deliveryRequestDto
    })
  }

  async fetchDeliveryRequests(type: string): Promise<Array<SiteManagerDeliveryRequestBean>> {
    return this.get({
      path: `/api/mp/v1/delivery-request/site-manager/${type}`,
      authentication: 'required',
    })
  }
  
  async fetchDeliveryRequestByCode(code: string): Promise<SiteManagerDeliveryRequestBean> {
    return this.get({
      path: `/api/mp/v1/delivery-request/by-code/${code}`,
      authentication: 'required'
    })
  }

  async disputeDeliveryRequest(params: { id: string; disputeDto: DisputeDto; picture: Picture }): Promise<void> {
    const formData = new FormData()
    
    if(!_.isEmpty(params.picture)) {
      await AbstractPictureApi.addPictureToFormData(formData, '', params.picture, 'disputePhoto')
    }
    
    formData.set('disputeDto', new Blob([JSON.stringify(params.disputeDto)], { type: 'application/json' }))
    
    return this.postVoid({
      path: `/api/mp/v1/delivery-request/${params.id}/dispute`,
      authentication: 'required',
      rawBody: formData,
    })
  }


}
